import type { FC } from 'react';
import React from 'react';

import { withContent, getStaticContentProps } from '@jerry-serverless/seo-next-ssg-helpers';

import { HomePage } from '../components/HomePage';
import { App } from '../components/App';

interface HomePageRouteProps {
  cookieBannerType?: number;
}

export const HomePageRoute: FC<HomePageRouteProps> = ({ cookieBannerType }) => {
  return (
    <App pageSchemaTypeList={['financial-service', 'software-application']}>
      <HomePage cookieBannerType={cookieBannerType} />
    </App>
  );
};

/**
 * Query page data at build or runtime for incremental static regeneration
 */
export const getStaticProps = getStaticContentProps({
  PageComponent: HomePageRoute,
  disableIsr: true,
});

/** withSeoCms is automatically false here because of the options from getStaticContentProps */
export default withContent(HomePageRoute);
