import type { FC } from 'react';
import React, { useEffect } from 'react';

import type { CardListBlockProps } from '@jerry-serverless/seo-sections/lib/CardListBlock/CardListBlock';

const { default: LazyHydrate } = await import(/* webpackChunkName: "HP-LazyHydrate" */ 'react-lazy-hydration');

const { default: HideMobileNavScript } = await import(
  /* webpackChunkName: "HP-HideMobileNavScript" */ '@jerry-serverless/seo-pages/lib/article/components/HideMobileNavScript'
);

const { TopNavigation } = await import(
  /* webpackChunkName: "HP-TopNavigation" */ '@jerry-serverless/seo-sections/lib/TopNavigation'
);
const { PageSeoMetaHead } = await import(
  /* webpackChunkName: "HP-PageSeoMetaHead" */ '@jerry-serverless/seo-apps-common'
);
const { stringifyRichText } = await import(
  /* webpackChunkName: "HP-stringifyRichText" */ '@jerry-serverless/seo-components/lib/PayloadRichTextParagraph'
);
const { PayloadHomepageHeroCta } = await import(
  /* webpackChunkName: "HP-PayloadHomepageHeroCta" */ '@jerry-serverless/seo-sections/lib/PayloadHomepageHeroCta'
);
const { trackEvent } = await import(/* webpackChunkName: "HP-trackEvent" */ '@jerry-serverless/analyticslib/frontend');
const { LogoList } = await import(/* webpackChunkName: "HP-LogoList" */ '@jerry-serverless/jerry-ui/lib/LogoList');
const { Section } = await import(/* webpackChunkName: "HP-Section" */ '@jerry-serverless/jerry-ui/lib/Section');
const { CardListBlock } = await import(
  /* webpackChunkName: "HP-CardListBlock" */ '@jerry-serverless/seo-sections/lib/CardListBlock/CardListBlock'
);
const { Typography } = await import(
  /* webpackChunkName: "HP-Typography" */ '@jerry-serverless/jerry-ui/lib/Typography'
);
const { Lines } = await import(/* webpackChunkName: "HP-Lines" */ '@jerry-serverless/jerry-ui/lib/Lines');
const { UserCommentList } = await import(
  /* webpackChunkName: "HP-UserCommentList" */ '@jerry-serverless/seo-sections/lib/UserCommentList'
);
const { RideWithUsCta } = await import(
  /* webpackChunkName: "HP-RideWithUsCta" */ '@jerry-serverless/seo-sections/lib/RideWithUsCta'
);
const { BottomCtaBanner } = await import(
  /* webpackChunkName: "HP-BottomCtaBanner" */ '@jerry-serverless/ui-components/lib/BottomCtaBanner'
);
const { ResourceLinkListV2 } = await import(
  /* webpackChunkName: "HP-ResourceLinkListV2" */ '@jerry-serverless/seo-sections/lib/ResourceLinkListV2'
);

const { CookieBanner } = await import(/* webpackChunkName: "HP-CookieBanner" */ '../CookieBanner/CookieBanner');

const { S } = await import(/* webpackChunkName: "HP-S" */ './styled.HomePage');
const { ctaButtons } = await import(/* webpackChunkName: "HP-ctaButtons" */ './const/ctaButtons');
const { logoListLogos } = await import(/* webpackChunkName: "HP-logoListLogos" */ './const/logoListLogos');
const { repeatedUserComments } = await import(
  /* webpackChunkName: "HP-repeatedUserComments" */ './const/repeatedUserComments'
);
const { meta } = await import(/* webpackChunkName: "HP-meta" */ './const/meta');
const { allCarCardListBlock } = await import(
  /* webpackChunkName: "HP-allCarCardListBlock" */ './const/allCarCardListBlock'
);
const { dataLockCardListBlock } = await import(
  /* webpackChunkName: "HP-dataLockCardListBlock" */ './const/dataLockCardListBlock'
);

interface HomePageProps {
  cookieBannerType?: number;
}

/**
 * HomePage
 */
export const HomePage: FC<HomePageProps> = ({ cookieBannerType }) => {
  useEffect(() => {
    void trackEvent({
      eventCategory: 'Seo',
      eventAction: 'ABTest',
      eventLabel: 'HomePage',
      eventMetadata: { variation: cookieBannerType || 0 },
    });
  }, [cookieBannerType]);

  return (
    <S.HomePage>
      <LazyHydrate whenIdle whenVisible>
        <PageSeoMetaHead meta={meta} stringifyRichText={stringifyRichText} />
      </LazyHydrate>
      <LazyHydrate whenIdle whenVisible>
        <TopNavigation variant="primary" />
      </LazyHydrate>
      <LazyHydrate whenIdle whenVisible>
        <PayloadHomepageHeroCta ctaButtons={ctaButtons} />
      </LazyHydrate>
      <LazyHydrate whenIdle whenVisible>
        <HideMobileNavScript id="nav_second" />
      </LazyHydrate>
      <LazyHydrate whenIdle whenVisible>
        <Section
          title="As seen on"
          titleVariant="link-md"
          gap={0}
          paddingBottom={0}
          titleProps={{
            textColor: 'GrayscaleLabel',
            textAlign: 'center',
          }}
          visible={true}
          variant="block"
          paddingTop={{ xs: '12px', md: '1vw' }}
          borderBottom={true}
          backgroundColor="alternative"
        >
          <LogoList
            maskOpacity={0.1}
            logoHeight="40%"
            logoWidth={{ xs: '125px', md: '10vw' }}
            logos={logoListLogos}
            animationTime={2}
          />
        </Section>
      </LazyHydrate>
      <LazyHydrate whenIdle whenVisible>
        <CardListBlock block={allCarCardListBlock as unknown as CardListBlockProps['block']} />
      </LazyHydrate>
      <LazyHydrate whenIdle whenVisible>
        <Section
          titleProps={{
            textAlign: 'center',
          }}
          withContainer={true}
          visible={true}
          variant="block"
          paddingTop="6vw"
          paddingBottom="0vw"
          backgroundColor="white"
        >
          <Typography
            className="score"
            variant="h1"
            component="h2"
            fontSize={34}
            lineHeight={1}
            textColor="GrayscaleOffBlack"
            textAlign="center"
          >
            <Lines inline={false}>
              <span>We love saving our customers money.</span>
              <span>Turns out, they love it too</span>
            </Lines>
          </Typography>
          <Section fullWidth paddingTop={0} paddingBottom={0}>
            <UserCommentList comments={repeatedUserComments} />
          </Section>
          <Section fullWidth paddingTop={0} paddingBottom={0}>
            <RideWithUsCta
              title="Go ahead—join us."
              cta="Get a free account"
              bulletPoints={[{ text: 'No long forms' }, { text: 'No spam or unwanted phone calls' }]}
              link="https://getjerry.com/app"
            />
          </Section>
        </Section>
      </LazyHydrate>
      <LazyHydrate whenIdle whenVisible>
        <CardListBlock block={dataLockCardListBlock as unknown as CardListBlockProps['block']} />
      </LazyHydrate>
      <LazyHydrate whenIdle whenVisible>
        <Section
          paddingTop="5vw"
          paddingBottom="5vw"
          visible={true}
          variant="block"
          fullWidth={true}
          borderTop={true}
          borderBottom={true}
          backgroundColor="GrayscaleBackground"
        >
          <ResourceLinkListV2 />
        </Section>
      </LazyHydrate>
      <LazyHydrate whenIdle whenVisible>
        <Section fullWidth paddingTop={0} paddingBottom={0}>
          <BottomCtaBanner
            cta="Get Started"
            link="https://getjerry.com/signup/servicesplit"
            hiddenInTopFirstScreen={true}
          />
        </Section>
      </LazyHydrate>
      <LazyHydrate whenIdle whenVisible>
        <CookieBanner visible={cookieBannerType === 1} />
      </LazyHydrate>
    </S.HomePage>
  );
};
