import React from 'react';
import get from 'lodash/get';
import filter from 'lodash/filter';

import type { ContentPageProps, ContentOptionsOrPageComponent } from './types.withContent';

const { ApolloProvider } = await import(/* webpackChunkName: "ApolloProvider" */ '@apollo/client');

const { default: ContentfulProvider } = await import(
  /* webpackChunkName: "ContentfulProvider" */ 'react-contentful/ContentfulProvider'
);

const { createContentfulClient } = await import(
  /* webpackChunkName: "createContentfulClient" */ '@jerry-serverless/contentful-api'
);

const { initializeContentCsrApollo } = await import(
  /* webpackChunkName: "initializeContentCsrApollo" */ '@jerry-serverless/graphql-helpers/apollo/content-client'
);
const { createPayloadClient } = await import(
  /* webpackChunkName: "createPayloadClient" */ '@jerry-serverless/payload-api/server'
);
const { PayloadApiProvider } = await import(
  /* webpackChunkName: "PayloadApiProvider" */ '@jerry-serverless/payload-api'
);

/**
 * Get contentful cache from static props and restore contentfulClient on CSR
 * refer link: https://github.com/TheRusskiy/next-apollo-ts/blob/master/components/withApollo/index.tsx
 */
export function withContent<T>(optionsOrPageComponent: ContentOptionsOrPageComponent<T>) {
  const options =
    typeof optionsOrPageComponent === 'function'
      ? {
          PageComponent: optionsOrPageComponent,
        }
      : optionsOrPageComponent;

  const { PageComponent } = options;

  const PageComponentWithSeoCms = (props: ContentPageProps<T>) => {
    const { preview, contentfulCache, cmsCache, payloadCache, errorMessage, errorStack, space } = props;

    if (errorMessage || errorStack) {
      const exitPreviewLink =
        typeof window !== 'undefined' ? `/api/preview?exit=1&url=${encodeURIComponent(window.location.pathname)}` : '';

      const contentfulErrors = filter(contentfulCache, '[1].errors').map(([query, res]) => ({
        query,
        errors: get(res, 'errors'),
      }));

      return (
        <div>
          <h1>Unexpected {preview && 'Preview '}Error</h1>
          {preview && (
            <p>
              Click here to <a href={exitPreviewLink}>exit preview mode</a> and check if it's really broken. If not,
              it's highly possible caused by draft/broken contents of contentful. <br />
              Check the <a href="#contentful-errors">contentful errors</a> to see if they inspires you.
            </p>
          )}
          <h3>Error message:</h3>
          <p>{errorMessage}</p>
          <h3>Error stack:</h3>
          <code style={{ whiteSpace: 'break-spaces' }}>{errorStack}</code>
          <h3 id="contentful-errors">Contentful errors:</h3>
          <code style={{ whiteSpace: 'break-spaces' }}>{JSON.stringify(contentfulErrors, null, 2)}</code>
          <h3>Contentful cache:</h3>
          <code style={{ whiteSpace: 'break-spaces' }}>{JSON.stringify(contentfulCache, null, 2)}</code>
          <h3>Cms cache:</h3>
          <code style={{ whiteSpace: 'break-spaces' }}>{JSON.stringify(cmsCache, null, 2)}</code>
          <h3>Payload cache:</h3>
          <code style={{ whiteSpace: 'break-spaces' }}>{JSON.stringify(payloadCache, null, 2)}</code>
        </div>
      );
    }

    const apolloClient = initializeContentCsrApollo(undefined, JSON.parse(cmsCache || '{}'));
    const contentfulClient = createContentfulClient({
      preview,
      space,
      cache: contentfulCache || '',
    });
    const payloadClient = createPayloadClient({
      cacheObject: JSON.parse(payloadCache || '{}'),
    });

    return (
      <ApolloProvider client={apolloClient}>
        <PayloadApiProvider client={payloadClient}>
          <ContentfulProvider client={contentfulClient}>
            <PageComponent {...props} />
          </ContentfulProvider>
        </PayloadApiProvider>
      </ApolloProvider>
    );
  };

  // Set the correct displayName in development
  if (process.env.NODE_ENV !== 'production') {
    const displayName = PageComponent.displayName || PageComponent.name || 'Component';
    PageComponentWithSeoCms.displayName = `withContent(${displayName})`;
  }

  return PageComponentWithSeoCms;
}
